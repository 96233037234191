import { Image } from "react-grid-gallery";

export interface CustomImage extends Image {
  original: string;
  service: string; // Nuevo campo
}

export const images: CustomImage[] = [
  {
    src: "https://yourfiles.cloud/uploads/f21ee38e2b35217475f8ab6c69b18f46/WhatsApp%20Image%202025-01-07%20at%2019.06.57_c98fe378.jpg",
    original: "https://yourfiles.cloud/uploads/f21ee38e2b35217475f8ab6c69b18f46/WhatsApp%20Image%202025-01-07%20at%2019.06.57_c98fe378.jpg",
    width: 320,
    height: 174,
    tags: [
      { value: "exterior", title: "exterior" },
      { value: "", title: "" },
    ],
    caption: "exterior",
    service: "painting",
  },
  {
    src: "https://yourfiles.cloud/uploads/196ad4046a5ea548dd2fd9f91b44d91f/WhatsApp%20Image%202025-01-07%20at%2019.06.59_79be7f66.jpg",
    original: "https://yourfiles.cloud/uploads/196ad4046a5ea548dd2fd9f91b44d91f/WhatsApp%20Image%202025-01-07%20at%2019.06.59_79be7f66.jpg",
    width: 320,
    height: 212,
    caption: "Boats (Jeshu John - designerspics.com)",
    service: "carpentry",
  },
  {
    src: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg",
    original: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg",
    width: 320,
    height: 212,
    caption: "Color Pencils (Jeshu John - designerspics.com)",
    service: "drywall",
  },
  {
    src: "https://yourfiles.cloud/uploads/6ec1ebe7f5db52a2edacc25a08265057/WhatsApp%20Image%202025-01-07%20at%2015.20.31_c5c721ec.jpg",
    original: "https://yourfiles.cloud/uploads/6ec1ebe7f5db52a2edacc25a08265057/WhatsApp%20Image%202025-01-07%20at%2015.20.31_c5c721ec.jpg",
    width: 320,
    height: 213,
    caption: "exterior",
    service: "painting",
  },
  {
    src: "https://yourfiles.cloud/uploads/196ad4046a5ea548dd2fd9f91b44d91f/WhatsApp%20Image%202024-12-29%20at%2022.15.25_9143327f.jpg",
    original: "https://yourfiles.cloud/uploads/196ad4046a5ea548dd2fd9f91b44d91f/WhatsApp%20Image%202024-12-29%20at%2022.15.25_9143327f.jpg",
    width: 320,
    height: 183,
    caption: "37H (gratispgraphy.com)",
    service: "carpentry",
  },
  {
    src: "https://c5.staticflickr.com/9/8768/28941110956_b05ab588c1_b.jpg",
    original: "https://c5.staticflickr.com/9/8768/28941110956_b05ab588c1_b.jpg",
    width: 240,
    height: 320,
    tags: [{ value: "Nature", title: "Nature" }],
    caption: "8H (gratisography.com)",
    service: "drywall",
  },
  {
    src: "https://yourfiles.cloud/uploads/e831f605ca507c2cabd688ee960a6474/f130823a-e3fd-4f74-afeb-f52773a051c7.jpeg",
    original: "https://yourfiles.cloud/uploads/e831f605ca507c2cabd688ee960a6474/f130823a-e3fd-4f74-afeb-f52773a051c7.jpeg",
    width: 320,
    height: 190,
    caption: "interior",
    service: "painting",
  },
  {
    src: "https://c7.staticflickr.com/9/8569/28941134686_d57273d933_b.jpg",
    original: "https://c7.staticflickr.com/9/8569/28941134686_d57273d933_b.jpg",
    width: 320,
    height: 148,
    tags: [{ value: "People", title: "People" }],
    caption: "315H (gratisography.com)",
    service: "carpentry",
  },
  {
    src: "https://c6.staticflickr.com/9/8342/28897193381_800db6419e_b.jpg",
    original: "https://c6.staticflickr.com/9/8342/28897193381_800db6419e_b.jpg",
    width: 320,
    height: 213,
    caption: "201H (gratisography.com)",
    service: "drywall",
  },
  {
    src: "https://yourfiles.cloud/uploads/d218c3d5a2aab51a8be40b95b902071d/d51872d1-5b42-4032-b7fb-2dfadc0bfc3f.jpeg",
    original: "https://yourfiles.cloud/uploads/d218c3d5a2aab51a8be40b95b902071d/d51872d1-5b42-4032-b7fb-2dfadc0bfc3f.jpeg",
    alt: "Big Ben - London",
    width: 248,
    height: 320,
    caption: "sanding",
    service: "painting",
  },
  {
    src: "https://c7.staticflickr.com/9/8785/28687743710_3580fcb5f0_b.jpg",
    original: "https://c7.staticflickr.com/9/8785/28687743710_3580fcb5f0_b.jpg",
    alt: "Red Zone - Paris",
    width: 320,
    height: 113,
    tags: [{ value: "People", title: "People" }],
    caption: "Red Zone - Paris (Tom Eversley - isorepublic.com)",
    service: "carpentry",
  },
  {
    src: "https://c6.staticflickr.com/9/8520/28357073053_cafcb3da6f_b.jpg",
    original: "https://c6.staticflickr.com/9/8520/28357073053_cafcb3da6f_b.jpg",
    alt: "Wood Glass",
    width: 313,
    height: 320,
    caption: "Wood Glass (Tom Eversley - isorepublic.com)",
    service: "drywall",
  },
  {
    src: "https://yourfiles.cloud/uploads/3ec3e47c7041093eda9d0442c112c808/IMG-20250107-WA0043.jpg",
    original: "https://yourfiles.cloud/uploads/3ec3e47c7041093eda9d0442c112c808/IMG-20250107-WA0043.jpg",
    width: 320,
    height: 213,
    caption: "sanding",
    service: "painting",
  },
  {
    src: "https://c4.staticflickr.com/9/8562/28897228731_ff4447ef5f_b.jpg",
    original: "https://c4.staticflickr.com/9/8562/28897228731_ff4447ef5f_b.jpg",
    width: 320,
    height: 194,
    caption: "Old Barn (Tom Eversley - isorepublic.com)",
    service: "carpentry",
  },
  {
    src: "https://c2.staticflickr.com/8/7577/28973580825_d8f541ba3f_b.jpg",
    original: "https://c2.staticflickr.com/8/7577/28973580825_d8f541ba3f_b.jpg",
    alt: "Cosmos Flower",
    width: 320,
    height: 213,
    caption: "Cosmos Flower Macro (Tom Eversley - isorepublic.com)",
    service: "drywall",
  },
  {
    src: "https://yourfiles.cloud/uploads/b269e0de1589ac0c0e053cb7af5e0ea7/IMG-20250107-WA0047.jpg",
    original: "https://yourfiles.cloud/uploads/b269e0de1589ac0c0e053cb7af5e0ea7/IMG-20250107-WA0047.jpg",
    width: 271,
    height: 320,
    caption: "powerwash",
    service: "painting",
  },
  {
    src: "https://c1.staticflickr.com/9/8330/28941240416_71d2a7af8e_b.jpg",
    original: "https://c1.staticflickr.com/9/8330/28941240416_71d2a7af8e_b.jpg",
    width: 320,
    height: 213,
    tags: [
      { value: "Nature", title: "Nature" },
      { value: "People", title: "People" },
    ],
    caption: "Surfer Sunset (Tom Eversley - isorepublic.com)",
    service: "carpentry",
  },
  {
    src: "https://c1.staticflickr.com/9/8707/28868704912_cba5c6600e_b.jpg",
    original: "https://c1.staticflickr.com/9/8707/28868704912_cba5c6600e_b.jpg",
    width: 320,
    height: 213,
    tags: [
      { value: "People", title: "People" },
      { value: "Sport", title: "Sport" },
    ],
    caption: "Man on BMX (Tom Eversley - isorepublic.com)",
    service: "drywall",
  },
  {
    src: "https://yourfiles.cloud/uploads/ed2ef17adaf8affa3272a7206aae0833/WhatsApp%20Image%202025-01-08%20at%2000.05.38_65e1702e.jpg",
    original: "https://yourfiles.cloud/uploads/ed2ef17adaf8affa3272a7206aae0833/WhatsApp%20Image%202025-01-08%20at%2000.05.38_65e1702e.jpg",
    width: 320,
    height: 213,
    caption: "interior",
    service: "painting",
  },
  {
    src: "https://c4.staticflickr.com/8/7476/28973628875_069e938525_b.jpg",
    original: "https://c4.staticflickr.com/8/7476/28973628875_069e938525_b.jpg",
    width: 320,
    height: 213,
    caption: "Time to Think (Tom Eversley - isorepublic.com)",
    service: "carpentry",
  },
  {
    src: "https://c6.staticflickr.com/9/8593/28357129133_f04c73bf1e_b.jpg",
    original: "https://c6.staticflickr.com/9/8593/28357129133_f04c73bf1e_b.jpg",
    width: 320,
    height: 179,
    tags: [
      { value: "Nature", title: "Nature" },
      { value: "Fauna", title: "Fauna" },
    ],
    caption: "Untitled (Jan Vasek - jeshoots.com)",
    service: "drywall",
  },
  {
    src: "https://yourfiles.cloud/uploads/dc868f8eac185734eae41fa780866342/IMG-20250107-WA0045.jpg",
    original: "https://yourfiles.cloud/uploads/dc868f8eac185734eae41fa780866342/IMG-20250107-WA0045.jpg",
    width: 320,
    height: 215,
    tags: [{ value: "People", title: "People" }],
    caption: "interior",
    service: "painting",
  },
  {
    src: "https://c1.staticflickr.com/9/8056/28354485944_148d6a5fc1_b.jpg",
    original: "https://c1.staticflickr.com/9/8056/28354485944_148d6a5fc1_b.jpg",
    width: 257,
    height: 320,
    caption: "A photo by 贝莉儿 NG. (unsplash.com)",
    service: "carpentry",
  },
  {
    src: "https://c7.staticflickr.com/9/8824/28868764222_19f3b30773_b.jpg",
    original: "https://c7.staticflickr.com/9/8824/28868764222_19f3b30773_b.jpg",
    width: 226,
    height: 320,
    caption: "A photo by Matthew Wiebe. (unsplash.com)",
    service: "drywall",
  },
];
